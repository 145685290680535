import React, { PureComponent } from 'react'
import { graphql } from 'gatsby'

import '../assets/styles/app.scss'
import get from 'lodash/get'
import { withHealthPointServiceContext } from '../contexts/HealthPointServiceContext'
import { HealthPointPageLayout } from '../components/layouts'

class HealthPointServiceTemplate extends PureComponent {
    render() {
        const service = get(this.props, 'data.healthPointServices')
        let sitemap = get(this.props, 'data.allContentfulWidgetSitemap.edges', [])
        sitemap = sitemap.map(link => link && link.node)
        return <HealthPointPageLayout service={service} sitemap={sitemap} />
    }
}

export default withHealthPointServiceContext(HealthPointServiceTemplate)

export const pageQuery = graphql`
    query HealthPointServiceByName($name: String!) {
        healthPointServices(name: { eq: $name }) {
            id
            meta {
                versionId
                lastUpdated
            }
            location {
                id
                reference
                type
                display
            }
            name
            comment
            photo {
                contentType
                url
                size
            }
            telecom {
                system
                value
            }
            extension {
                url
                extension {
                    url
                    valueString
                    extension {
                        url
                        valueString
                        valueReference {
                            reference
                            type
                            display
                        }
                        valueAddress {
                            use
                            type
                            line
                            city
                            district
                            postalCode
                            country
                        }
                        valueContactPoint {
                            system
                            value
                        }
                        extension {
                            url
                            valueDecimal
                        }
                        valueAttachment {
                            contentType
                            url
                            size
                            title
                        }
                    }
                }
            }
        }
        allContentfulWidgetSitemap(filter:{title: {ne: "Don't delete - sitemap"}}) {
            edges {
                node {
                    contentful_id
                    userSection
                    title
                    slug
                    links {
                        __typename
                        ... on ContentfulPageCustom {
                            slug
                            title
                            contentful_id
                            userSection
                        }
                        ... on ContentfulPageDirectoryOfServices {
                            id
                            title
                        }
                        ... on ContentfulPageDonation {
                            slug
                            title
                            contentful_id
                        }
                        ... on ContentfulPageGeneric {
                            slug
                            title
                            userSection
                        }
                        ... on ContentfulPageGroup {
                            slug
                            title
                            userSection
                        }
                        ... on ContentfulPageGuideline {
                            title
                            slug
                        }
                        ... on ContentfulPageWidgets {
                            title
                            slug
                            userSection
                        }
                    }
                }
            }
        }
    }
`
